import React from "react";
import chroma from 'chroma-js';
import styled from "styled-components";

const scale = chroma.scale('Viridis');


type HeatmapProps = {
  data: Array<Array<number>>,
  onClick?: (x: number, y: number) => any,
};

const Heatmap: React.FC<HeatmapProps> = ({data, onClick}) => {
  const rows = data.length;
  const cols = rows > 0 ? data[0].length : 0;

  return (
    <SvgHeatmap viewBox={`0 0 ${cols} ${rows}`}>
      {data.map((row, y) =>
        row.map((value, x) =>
          <Cell
            key={`${x}.${y}`}
            x={x}
            y={y}
            value={value}
            onClick={onClick && (() => onClick(x, y))}
          />
        ))}
    </SvgHeatmap>
  )
};


const SvgHeatmap = styled.svg`
  display: block;
  width: 100%;
  margin: auto;
`;

const Rect = styled.rect`
  cursor: pointer;
`;


type CellProps = {
  x: number,
  y: number,
  value: number,
  onClick?: () => any
};

const Cell: React.FC<CellProps> = ({x, y, value, onClick}) => {
  const padding = 0.05;
  const size = 1 - 2 * padding;
  const radius = 0.25 * size;

  return (
    <Rect
      x={x + padding}
      y={y + padding}
      width={size}
      height={size}
      fill={scale(value).css()}
      rx={radius}
      ry={radius}
      onClick={onClick}
    />
  )
};

export default Heatmap;
