import React, {useReducer} from 'react';
import './App.css';
import Heatmap from "./Heatmap";
import styled from "styled-components";
import examples from "./data/examples.json";

const App: React.FC = () => {
  const [{articleIdx, selection}, dispatch] = useReducer(appReducer, {articleIdx: 0, selection: selectRandom(examples[0])});
  const article = examples[articleIdx];

  const quote = article.quoted[selection.y];
  const answer = article.answers[selection.x];
  const score = article.affinity_matrix[selection.y][selection.x];

  return (
    <Container>
        <Header>
          <PageLink side="left" onClick={() => dispatch({type: 'prevPage'})}>&lsaquo;</PageLink>
          <Heatmap data={article.affinity_matrix} onClick={(x, y,) => dispatch({type: 'select', x, y})}/>
          <PageLink side="right" onClick={() => dispatch({type: 'nextPage'})}>&rsaquo;</PageLink>
        </Header>

        <Details>
          <h1><a href={article.url} target="_blank">{article.title}</a></h1>
          <Quote>{quote}</Quote>
          <Answer>
            <h2>{answer.title}</h2>
            {answer.body}
          </Answer>

          <InfoBar>{`Score: ${(score*100).toFixed(0)}%`}</InfoBar>
        </Details>
    </Container>
  );
};

type AppState = {
  articleIdx: number,
  selection: {x: number, y: number},
};

type AppAction =
  {type: 'nextPage'}
  | {type: 'prevPage'}
  | {type: 'select', x: number, y: number};

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "prevPage":
    case "nextPage":
      const offset = action.type === "prevPage" ? -1 : +1;
      const nextIdx = Math.max(0, Math.min(state.articleIdx+offset, examples.length-1));
      if (nextIdx != state.articleIdx) {
        return {
          articleIdx: nextIdx,
          selection: selectRandom(examples[nextIdx]),
        };
      } else {
        return state;
      }
    case "select":
      return {
        ...state,
        selection: {x: action.x, y: action.y},
      }
  }
};

const selectRandom = (example: typeof examples[0]) => ({
  x: randInt(0, example.answers.length),
  y: randInt(0, example.quoted.length),
});

const randInt = (min: number, max: number): number => {
  return Math.floor(min + Math.random() * (max-min))
};

const Container = styled.div`
  min-height: 100vh;
  padding: 9rem 2rem 4rem 2rem;
  box-sizing: border-box;

  background-color: #252a3a;
  font-size: 1.3rem;
  color: white;
`;

const Header = styled.div`
  --offset: 5rem;
  max-width: calc(75rem + 2 * var(--offset));
  margin: auto;
  
  display: grid;
  grid-template-columns: var(--offset) 1fr var(--offset);
  grid-template-rows: 1auto;
  grid-template-areas: ". . .";
`;

const PageLink = styled.div<{side: 'left' | 'right'}>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.side === 'left' ? 'flex-start': 'flex-end'};
  font-size: 6rem;
  transform: scale(1, 1.8);
  color: #555d67;
  padding: 0 0.5rem;
  cursor: pointer;
`;

const Details = styled.div`
  max-width: 55rem;
  padding: 0 3rem;
  box-sizing: auto;
  margin: auto;
`;

const Quote = styled.p`
`;

const Answer = styled.div`
  border-left: 0.3rem solid rgba(255, 255, 255, 0.6);
  padding: 0.7rem 1.5rem;
  margin: 2.1rem 0 0 1rem;
`;

const InfoBar = styled.div`
  text-align: end;
`;

export default App;
